<template>
  <div class="home">
    <commonTop class="home-top">
      <img
        slot="before"
        src="../assets/img/Group1174@2x.png"
        class="phone"
        alt=""
      />
    </commonTop>
    <common-title
      title="随心交易 售后保障"
      explain="— 高效客服团队，用理性专业温暖用户 —"
    ></common-title>
    <div class="number-text">线上鉴别量已超过600,000,000</div>
    <img src="../assets/img/part2@2x.png" class="part2" alt="" />
    <div class="btn">立即发布</div>
    <common-title
      title="迅捷分享 以卡会友"
      explain="— 快速知悉卡圈趣闻，一键种草心仪好卡 —"
    ></common-title>
    <div class="banner">
      <el-carousel
        :interval="4000"
        type="card"
        height="560px"
        :autoplay="true"
        indicator-position="none"
        arrow="never"
      >
        <el-carousel-item v-for="item in imgList" :key="item.id">
          <div class="img-bg">
            <img :src="item.url" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import commonTitle from "../components/commonTitle";
import commonTop from "../components/commonTop";
export default {
  name: "HomePage",
  components: {
    commonTitle,
    commonTop,
  },
  data() {
    return {
      imgList: [
        { id: "1", url: require("@/assets/img/carousel/1@2x.png") },
        { id: "2", url: require("@/assets/img/carousel/2@2x.png") },
        { id: "3", url: require("@/assets/img/carousel/3@2x.png") },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.home {
  background: #ffffff;
  text-align: center;
  .home-top {
    margin-bottom: 100px;
    .phone {
      width: 250px;
      height: 500px;
      z-index: 3;
      margin-right: 129px;
    }
  }
  .number-text {
    color: #000000;
    font-size: 30px;
    margin-top: 86px;
  }
  .part2 {
    width: 968px;
    /*border: 1px dashed #999999;*/
    margin-top: 267px;
  }
  .btn {
    width: 346px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background: #31b5ff;
    border-radius: 0px 0px 0px 0px;
    font-size: 20px;
    color: #ffffff;
    margin: 20px auto 198px auto;
    cursor: pointer;
  }
  .btn:hover {
    opacity: 0.8;
  }
  .banner {
    margin-top: 110px;
    margin-bottom: 254px;
    padding: 0 330px;
    ::v-deep .el-carousel__container {
      height: 560px !important;
      .el-carousel__item--card {
        width: 968px;
        margin-left: -14%;
      }
      .el-carousel__item {
        width: 968px;
        height: 560px;
      }
    }
    /*::v-deep .el-carousel__item--card {*/
    /*  width: 968px !important;*/
    /*}*/
    .img-bg {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #31b5ff 0%,
        #2286bf 62%,
        #3167cf 100%
      );
      box-shadow: 0px 22px 35px 10px rgba(26, 75, 121, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
