<template>
  <div class="common-top-bg">
    <div class="top-nav">
      <img src="../assets/img/logo@2x_text.png" class="logo" alt="" />
      <div class="nav-list">
        <div
          v-for="item in navList"
          :key="item.path"
          class="nav-item"
          :class="activePath === item.path ? 'active' : ''"
          @click="jump(item.path)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <slot name="before"> </slot>
      <div class="right">
        <div class="right-top">
          <div class="first-row">
            <img src="../assets/img/logo.png" alt="" />
            <span class="text">社区类球星卡收藏交易平台</span>
          </div>
          <div class="second-row">
            <span class="text">内置卡价查询功能 实时掌握市场行情</span>
          </div>
        </div>
        <div class="right-bottom">
          <div class="left">
            <div class="row">
              <img src="../assets/img/ios_icon@2x.png" alt="" />
              <span>IOS</span>
            </div>
            <div class="row">
              <img src="../assets/img/android_icon@2x.png" alt="" />
              <span>Android</span>
            </div>
          </div>
          <div class="qr-code">
            <canvas class="qr-code-img" id="QRCode_header"></canvas>
          </div>
        </div>
      </div>
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "commonLogo",
  data() {
    return {
      QRCodeMsg: "https://mp.weixin.qq.com/cgi-bin/wx",
      navList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "关于我们",
          path: "/about",
        },
      ],
      activePath: "/",
    };
  },
  methods: {
    jump(path) {
      if (path === this.$route.path) {
        return;
      }
      this.$router.push({
        path: path,
      });
    },
    getQRCode(id = "QRCode_header", QRCodeMsg = "QRCodeMsg") {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 1, //二维码留白边距
        width: 155, //宽
        height: 155, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      let msg = document.getElementById(id);
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this[QRCodeMsg], opts, function (error) {
        console.log(error);
      });
    },
  },
  mounted() {
    this.activePath = this.$route.path;
    console.log("path", this.$route.path);
    this.getQRCode("QRCode_header", "QRCodeMsg");
  },
};
</script>

<style lang="scss" scoped>
.common-top-bg {
  position: relative;
  width: 100%;
  height: 948px;
  background: rgba(0, 0, 0, 0.85) url("../assets/img/part1@2x.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-nav {
    padding-top: 26px;
    padding-left: 88px;
    padding-right: 253px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-list {
      display: flex;
      align-items: center;
      .nav-item:hover,
      .nav-item.active {
        color: #ffffff;
      }
      .nav-item.active {
        color: #ffffff;
        &::after {
          content: "";
          display: block;
          width: 34px;
          height: 2px; /* 边框的厚度 */
          background: #ffffff; /* 边框的颜色 */
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: -8px;
        }
      }
      .nav-item {
        font-size: 20px;
        color: #7f7f8d;
        cursor: pointer;
        position: relative;
        padding: 6px 12px;
      }
      .nav-item:not(:last-child) {
        margin-right: 152px;
      }
    }
    .logo {
      width: 129px;
      z-index: 3;
    }
  }
  .bottom-content {
    display: flex;
    justify-content: center;
    margin-top: 153px;
    height: 100%;
    .right {
      .right-top {
        margin-bottom: 190px;
        .first-row,
        .second-row {
          display: flex;
          align-items: center;
        }
        .first-row {
          font-size: 64px;
          font-weight: 600;
          color: #ffffff;
          img {
            width: 66px;
            margin-right: 12px;
          }
        }
        .second-row {
          margin-top: 16px;
          .text {
            font-size: 48px;
            font-weight: 200;
            color: #999999;
          }
        }
      }
      .right-bottom {
        display: flex;
        .left {
          .row:first-child {
            margin-bottom: 33px;
          }
          .row {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 190px;
            height: 60px;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            img {
              width: 36px;
              /*border: 1px dashed #999999;*/
              box-sizing: border-box;
              margin-right: 14px;
            }
            span {
              font-size: 28px;
              color: #ffffff;
            }
          }
        }
        .qr-code {
          margin-left: 28px;
          width: 155px;
          height: 155px;
          background: #ffffff;
          .qr-code-img {
            width: 155px !important;
            height: 155px !important;
          }
        }
      }
    }
  }
  .part {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}
</style>
