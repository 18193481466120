<template>
  <div class="common-bottom-bg">
    <div class="bottom-top-content">
      <div class="column">
        <div class="title">声明</div>
        <div class="column-text">隐私政策</div>
        <div class="column-text">使用条款</div>
        <div class="column-text">知识产权</div>
        <div class="column-text">自律声明</div>
        <div class="column-text">廉政举报</div>
      </div>
      <div class="column">
        <div class="title">关注我们</div>
        <div class="column-text">
          <img src="../assets/img/wechat_icon@2x.png" alt="" />
          <span>微信公众号</span>
        </div>
        <div class="column-text">
          <img src="../assets/img/program_icon@2x.png" alt="" />
          <span>微信小程序</span>
        </div>
        <div class="column-text">
          <img src="../assets/img/microblog_icon@2x.png" alt="" />
          <span>微博</span>
        </div>
      </div>
      <div class="column">
        <div class="title">下载耀卡App</div>
        <div class="qr-code-bg">
          <div class="top">
            <div class="left">
              <canvas class="qr-code-img2" id="QRCode_header2"></canvas>
            </div>
            <div class="right">
              <div class="row">
                <img src="../assets/img/ios_icon@2x.png" alt="" />
                <span>App Store内搜索“耀卡”下载</span>
              </div>
              <div class="row">
                <img src="../assets/img/android_icon@2x.png" alt="" />
                <span>安卓应用市场内搜索“耀卡”下载</span>
              </div>
            </div>
          </div>
          <div class="bottom">微信或相机扫码下载</div>
        </div>
      </div>
    </div>
    <div class="bottom-line"></div>
    <div class="bottom-text">
      <div class="text-style">
        增值电信业务经营许可证 沪B2-20180281 |
        <span class="text-decoration"> 沪ICP备16019780号-9号</span>
      </div>
      <div class="text-style">
        网络文化经营许可证沪网文（2021）4421-366号 |
        <span class="text-decoration"> 沪公网安备31010902003239号</span>
      </div>
      <div class="text-style">
        医疗器械网络交易服务第三方平台备案:（沪）网械平台备字［2021］第00003号 |
        互联网药品信息服务资格证书（沪）-经营性-2020-0212
      </div>
      <div class="text-style">
        网信算备310109033582302220017号 | 网信算备310109033582304230013号
      </div>
      <div class="text-style">
        网信算备310109033582305230019号 | 网信算备310109033582301240019号
      </div>
      <div class="text-style">
        社区内容举报电话：400-891-9888 (工作时间：8:00-22:00);021-63066366
        (工作时间：8:00-19:00)
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "commonBottom",
  data() {
    return {
      QRCodeMsg1: "https://www.baidu.com",
      QRCodeMsg2: "",
    };
  },
  methods: {
    getQRCode(id = "QRCode_header", QRCodeMsg = "QRCodeMsg") {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 1, //二维码留白边距
        width: 196, //宽
        height: 196, //高
        text: "http://www.xxx.com", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      let msg = document.getElementById(id);
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this[QRCodeMsg], opts, function (error) {
        console.log(error);
      });
    },
  },
  mounted() {
    this.getQRCode("QRCode_header2", "QRCodeMsg1");
  },
};
</script>

<style lang="scss" scoped>
.common-bottom-bg {
  width: 100%;
  height: 944px;
  background: #000000;
  padding: 90px 246px 212px 246px;
  box-sizing: border-box;
  color: #ffffff;
  .bottom-top-content {
    display: flex;
    justify-content: space-between;
    .column {
      text-align: left;
      .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 31px;
      }
      .qr-code-bg {
        .top {
          display: flex;
          overflow: hidden;
          .left {
            width: 196px;
            height: 196px;
            background: #ffffff;
            margin-right: 26px;
            .qr-code-img2 {
              width: 196px !important;
              height: 196px !important;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .row:first-child {
              margin-bottom: 12px;
            }
            .row {
              padding-left: 21px;
              width: 384px;
              height: 92px;
              line-height: 92px;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              img {
                width: 27.3px;
                margin-right: 18px;
              }
              span {
                font-size: 20px;
              }
            }
          }
        }
        .bottom {
          font-size: 18px;
          margin-top: 12px;
        }
      }
      .column-text {
        font-size: 18px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        img {
          width: 32px;
          /*border: 1px dashed #999999;*/
          box-sizing: border-box;
          margin-right: 16px;
        }
      }
    }
  }
  .bottom-line {
    background: #d3d3d3;
    width: 100%;
    height: 2px;
    margin-top: 73px;
    margin-bottom: 66px;
  }
  .bottom-text {
    text-align: left;
    .text-style {
      font-size: 18px;
      white-space: nowrap;
      text-align: left;
      margin-bottom: 20px;
    }
    .text-decoration {
      text-decoration: underline;
    }
  }
}
</style>
