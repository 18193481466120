<template>
  <div
    id="app"
    :class="isScrolling ? 'app-scrolling' : ''"
    @scroll="handleScroll"
  >
    <router-view></router-view>
    <commonBottom></commonBottom>
  </div>
</template>
<script>
import commonBottom from "./components/commonBottom";
export default {
  name: "App",
  components: {
    commonBottom,
  },
  data() {
    return {
      isScrolling: false,
      scrollTimeout: null,
    };
  },
  mounted() {
    this.updateFontSize();
    window.addEventListener("resize", this.updateFontSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateFontSize);
  },
  methods: {
    handleScroll() {
      this.isScrolling = true;
      // 如果需要在停止滚动时执行某些操作，可以使用定时器检查滚动是否停止
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 1000); // 150毫秒内没有滚动事件则认为停止滚动
    },
    updateFontSize() {
      // 页面开始加载时修改font-size
      var html = document.getElementsByTagName("html")[0];
      var oWidth =
        document.body.clientWidth || document.documentElement.clientWidth;
      // 这里的1920是指设计图的大小,自己根据实际情况改变
      setTimeout(() => {
        html.style.fontSize = (oWidth / 1920) * 16 + "px";
      }, 300);
    },
  },
};
</script>
<style scoped>
#app {
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
