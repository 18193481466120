<template>
  <div class="common-title-bg" :class="explain ? 'common-title-bg-pt' : ''">
    <img src="../assets/img/YOURCARD@2x.png" class="letter location" alt="" />
    <span class="title location">{{ title }}</span>
    <span class="line location"></span>
    <span v-if="explain" class="explain">{{ explain }}</span>
  </div>
</template>

<script>
export default {
  name: "commonTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    explain: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.common-title-bg-pt {
  height: auto !important;
  padding-top: 130px;
  box-sizing: border-box;
}
.common-title-bg {
  position: relative;
  height: 100px;
  .location {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .letter {
    top: 0;
    width: 270px;
  }
  .title {
    color: #000000;
    font-size: 36px;
    top: 46px;
    font-weight: 600;
  }
  .line {
    width: 54px;
    height: 6px;
    background: #31b5ff;
    top: 90px;
  }
  .explain {
    color: #999999;
    font-size: 16px;
  }
}
</style>
