<template>
  <div class="about-us">
    <commonTop class="home-top">
      <div slot="after" class="prototype-bg">
        <img src="../assets/img/prototype.png" class="prototype" alt="" />
      </div>
    </commonTop>
    <div class="about-content">
      <common-title title="关于我们"></common-title>
      <div class="about-us-text-bg">
        <div class="about-us-text">
          耀卡 Your Card 成立于 2024年5月，是苏州水镜数语科技有限公司旗下产品。
        </div>
        <div class="about-us-text">
          耀卡APP长期专注于球星卡收藏、交易、互联网平台及解决方案的研发和运营，致力于给国内用户打造公开、公平、公正的交易平台，提供卡价查询、卡品甄选，卡单放送等实用功能，为初入卡圈的您扫清
        </div>
        <div class="about-us-text">障碍。</div>
        <div class="about-us-text">
          耀卡App拥有社区分享种草功能，天南海北的球星卡文化爱好者，聚集于此互通有无，共同壮大本土收
        </div>
        <div class="about-us-text">藏潮流发展的土壤。</div>
      </div>
      <img
        src="../assets/img/businessLicense.png"
        class="business-license"
        alt=""
      />
      <common-title title="联系我们"></common-title>
      <div class="mail">yaokaapp@163.com</div>
    </div>
  </div>
</template>

<script>
import commonTitle from "../components/commonTitle";
import commonTop from "../components/commonTop";
export default {
  name: "AboutUs",
  components: {
    commonTitle,
    commonTop,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.about-us {
  background: #ffffff;
  text-align: center;
  .home-top {
    margin-bottom: 91px;
    .prototype-bg {
      height: 100%;
      display: flex;
      align-items: flex-end;
      .prototype {
        width: 551px;
        height: 583px;
        margin-left: 14px;
      }
    }
  }
  .about-content {
    padding: 0 278px;
    width: 100%;
    box-sizing: border-box;
  }
  .business-license {
    width: 902px;
    /*border: 1px dashed #999999;*/
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 212px;
  }
  .about-us-text-bg {
    margin-top: 136px;
    .about-us-text {
      color: #6a6a6a;
      font-size: 30px;
      text-align: left;
      line-height: 2;
      font-weight: 300;
    }
  }
  .mail {
    color: #6a6a6a;
    font-weight: 300;
    font-size: 30px;
    margin-top: 136px;
    margin-bottom: 231px;
  }
}
</style>
